import logo from './logo.svg';
import './App.css';

function App() {



  var url = window.location.href

  var code = 'DIZ' + url.split('/DIZ')[1] 

  console.log(code)

  if (code == 'DIZB2405E1'){ //corrected-blr
    window.location.href = ('https://webform.doitzero.com/150/13')
  }
  else if (code == 'DIZB2405E2'){ //corrected-delhi
    window.location.href = ('https://webform.doitzero.com/150/12')
  }
  else if (code == 'DIZB2405E3'){ //corrected-mumbai
    window.location.href = ('https://webform.doitzero.com/150/15')
  }
  else if (code == 'DIZB2405E4'){
    window.location.href = ('https://webform.doitzero.com/150')
  }
  else if (code == 'DIZB2405E5'){
    window.location.href = ('https://webform.doitzero.com/150')
  }
  else if (code == 'DIZB2405T1'){ //corrected-delhi //corrected bangalore
    window.location.href = ('https://webform.doitzero.com/151')
  }
  else if (code == 'DIZB2405T2'){
    window.location.href = ('https://webform.doitzero.com/151')
  }
  else if (code == 'DIZB2405T3'){
    window.location.href = ('https://webform.doitzero.com/151')
  }
  else if (code == 'DIZB2405T4'){
    window.location.href = ('https://webform.doitzero.com/151')
  }
  else if (code == 'DIZB2405T5'){ //corrected (mum + blr)
    window.location.href = ('https://webform.doitzero.com/151')
  }
  else if (code == 'DIZB2405E6'){
    window.location.href = ('https://webform.doitzero.com/152/19')
  }
  else if (code == 'DIZB2405E7'){
    window.location.href = ('https://webform.doitzero.com/152/20')
  }
  else if (code == 'DIZB2405E8'){
    window.location.href = ('https://webform.doitzero.com/152/17')
  }
  else if (code == 'DIZB2405E9'){
    window.location.href = ('https://webform.doitzero.com/152/18')
  }
  else {
    //window.location.href = ('https://doitzero.com/')
  }


  return (
    <div className="App">
    <p>
      Opening..
    </p>
    </div>
  );
}

export default App;
